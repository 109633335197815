

import React from 'react'
import c from 'classnames'

export default function Section({ color, children, className }) {
  const classNames = c(
    'py-16 md:py-24',
    {
      'bg-black text-white': color === 'black',
      'bg-grey-light text-black': color === 'grey',
      'bg-white text-black': !color,
    },
    className
  )

  return <div className={classNames}>{children}</div>
}
