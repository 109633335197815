import React from 'react'
import { Link } from 'gatsby'
import { Carousel } from 'react-responsive-carousel'
import Section from '../components/Section'
import Feature from '../components/Feature'
import SEO from '../components/SEO'
import { graphql } from 'gatsby'

import Img from 'gatsby-image'

if (typeof window === 'undefined') {
  global.window = {}
}

function IndexPage({ data: { kitchen, bath, custom, customKitchen, gate } }) {
  return (
    <>
      <SEO
        title="Kitchen carpentry and design - Matys Carpentry"
        keywords={[
          'kitchen renovation',
          'kitchen ideas 2020',
          'carpentry near me',
          'carpentry',
          'kitchen rebuilding',
          'new construction',
          'home constructions',
          'home interior design',
          'woodworking',
          'carpentry near me',
          'Norwalk carpentry',
          'carpentry ct'
        ]}
        description="Honest, efficient, and eco-conscious, trust Matys Construction with turning your house into your dream home."
      />
      <div className="banner">
        <Carousel
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          autoPlay={true}
          infiniteLoop={true}
          interval={4500}
          stopOnHover={false}
        >
          <Img fluid={kitchen.childImageSharp.fluid} className="banner" />
          <Img fluid={customKitchen.childImageSharp.fluid} className="banner" />
          <Img fluid={gate.childImageSharp.fluid} className="banner" />
          <Img fluid={bath.childImageSharp.fluid} className="banner" />
          <Img fluid={custom.childImageSharp.fluid} className="banner" />

        </Carousel>
      </div>

      <Section>
        <div className="max-w-4xl m-auto">
          <div className="flex flex-col md:flex-row -my-5">
            <div className="px-5 flex flex-col flex-1 h-48 md:h-80 mb-8 md:mb-0">
              <div className="mb-2 py-2 text-center font-bold text-2xl">
                Kitchens
              </div>
              <Img fluid={kitchen.childImageSharp.fluid} className="h-80" />
            </div>
            <div className="px-5 flex flex-col flex-1 h-48 md:h-80  mb-8 md:mb-0">
              <div className="mb-2 py-2 text-center font-bold text-2xl">
                Bathrooms
              </div>
              <Img fluid={bath.childImageSharp.fluid} className="h-80" />
            </div>
            <div className="px-5 flex flex-col flex-1 h-48 md:h-80  mb-8 md:mb-0">
              <div className="mb-2 py-2 text-center font-bold text-2xl">
                Custom Woodworking
              </div>
              <Img fluid={custom.childImageSharp.fluid} className="h-80" />
            </div>
          </div>
        </div>
      </Section>

      <Section>
        <div className={'container -mt-16'}>
          <div className={'flex justify-center flex-col items-center'}>
            <div
              className={
                'max-w-lg px-6 flex justify-center text-lg md:text-2xl text-center'
              }
            >
              Find out how Matys Carpentry can turn your house into a home with
              expertly crafted, custom constructions
            </div>
            <div className={'mt-6'}>
              <Link
                to={`/services`}
                className="bg-black text-white inline-block appearance-none border border-black hover:text-white md:text-lg px-3 py-3 md:px-5 md:py-4 leading-tight rounded-none focus:outline-none my-2 no-underline whitespace-no-wrap"
              >
                Our Services
              </Link>
            </div>
          </div>
        </div>
      </Section>

      <Section color="grey">
        <div className="max-w-4xl m-auto flex flex-col items-center">
          <div className="font-bold text-3xl md:text-4xl">
            Why Work with Us?
          </div>
          <div className="flex flex-wrap mt-4 md:mt-8">
            {[
              {
                name: 'Local & Sustainable',
                description: `We use sustainable practices and make every effort to ensure the
                materials we use are locally sourced`
              },
              {
                name: 'Full-Service Customizations',
                description: `We realize your dreams, working with you to turn your house into a
                home to drive the value of your investment`
              },
              {
                name: 'Established Experts',
                description: `We have over 20 years of experience working with families and
                private clients on their constructions`
              }
            ].map((feature) => (
              <Feature key={feature.name} {...feature} />
            ))}
          </div>
        </div>
      </Section>

      <Section color="black">
        <div className="container flex flex-col items-center">
          <div className="font-bold text-2xl md:text-4xl">
            Want to learn more?
          </div>
          <div className="md:text-2xl text-center mt-4">
            Reach out for a free consultation and let us know how we can help
          </div>
          <Link
            to={`/contact`}
            className="bg-white text-balck inline-block appearance-none border border-black text-black md:text-lg mt-6 md:mt-8 px-3 py-3 md:px-5 md:py-4 leading-tight rounded-none focus:outline-none my-2 no-underline"
          >
            Contact Us
          </Link>
        </div>
      </Section>
    </>
  )
}

export const query = graphql`
  {
    kitchen: file(name: { eq: "kitchen_5" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bath: file(name: { eq: "bath_5" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    custom: file(name: { eq: "custom_18" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    customKitchen: file(name: { eq: "kitchen_14" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gate: file(name: { eq: "gate_3" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
