import React from 'react'

export default function Feature({ name, description }) {
  return (
    <article className="px-8 md:px-20 md:py-2 md:my-2 w-full lg:w-1/3">
      <div className="text-center mx-auto">
        <div className="md:mt-6">
          <div className="flex justify-center items-end h-24">
            <h1 className="text-xl md:text-3xl text-black font-bold mb-2 w-64">
              {name}
            </h1>
          </div>
          <p className="block text-grey mb-2 mt-4 text-lg">{description}</p>
        </div>
      </div>
    </article>
  )
}
